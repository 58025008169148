.App {
  text-align: center;
  padding: 64px 16px 64px 16px;
}

.root {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.App-logo {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  pointer-events: none;
}

.vgap-20 {
  margin-top: 20px;
}

.vgap-16 {
  margin-top: 16px;
}

.vgap-10 {
  margin-top: 10px;
}

.vgap-4 {
  margin-top: 4px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.link-set {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-layout {
  width: 100%;
  max-width: 680px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.layout-element-label-parent {
  margin: 16px 0 16px 0;
}

.layout-element-category span {
  font-weight: bold;
}

.layout-element-category-parent {
  padding: 32px 0 0 0;
}

.layout-element-parent {
  margin: 16px 0 16px 0;
}

.layout-element-button {
  background-color: white;
  color: black;
  border-radius: 14px;
  padding: 2px 2px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 1) 0s;
}

.layout-element-button:hover {
  transform: scale(1.02);
  transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 2) 0s;
}

.layout-element-button img {
  margin-left: 2px;
  height: 48px;
  max-width: 48px;
  border-radius: 12px;
}

.layout-element-button span {
  width: calc(100% - 48px);
  text-align: center;
}

.social-media {
  display: inline-block;
  margin-top: 16px;
  color: white;
}

.social-link-icon {
  display: inline;
  margin: 4px;
}

.social-link-icon img {
  height: 32px;
  width: 32px;
}

.link {
  color: #61dafb;
}
